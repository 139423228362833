import React from 'react';

const FrameLibs = ({frameLibs}) => (
  <>
    <ul>
      {frameLibs.map(item => (
        <li key={item.id}>{item.title}</li>
      ))}
    </ul>
  </>
);

export default FrameLibs;
